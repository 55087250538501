import { Link, useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import React from "react"
import logo from "../images/logo-landu.png"

const Header = ({ navigationItems, langKey }) => {
  const { languages, originalPath } = useI18next()

  const { t } = useTranslation()
  const renderNavigationItems = () => {
    return navigationItems.map(item => {
      if (item.slug === "home") {
        item.slug = ""
      }
      return (
        <li key={item.id} className="nav-item mx-3">
          <Link
            className="nav-link"
            activeClassName="active"
            to={"/" + item.slug}
          >
            {item.seo_title}
          </Link>
        </li>
      )
    })
  }
  return (
    <header>
      {/* <div className="container d-flex justify-content-end">
        <ul className="nav languages">
          {languages.map(lng => (
            <li className="nav-item" key={lng}>
              <Link
                className="nav-link text-uppercase"
                to={originalPath}
                language={lng}
              >
                {lng}
              </Link>
            </li>
          ))}
        </ul>
      </div> */}

      <nav className="navbar navbar-expand-lg navbar-light mb-5">
        <div className="container d-flex justify-content-between">
          <Link className="navbar-brand" to="/">
            <img className="img-fluid" height="180" alt="Logo Landu" src={logo}></img>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarContent"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              {renderNavigationItems()}
              <li className="nav-item mx-3">
                <a className="nav-link" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
